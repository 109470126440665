<template>
	<div class="buildLog">
		<ul v-loading="loading">
			<li v-for="(item, index) in logList" :key="index">
				<span class="time">{{ item.createTime }}</span>
				<span>{{ item.operateInfo }}</span>
			</li>
		</ul>
	</div>
</template>

<script>
import { checkBedLog } from '@/api/bedManage'
export default {
	// 床位详情--建设日志
	name: 'buildLog',
	props: {
		bedInfo: {
			type: Object,
			default: () => {
				return {}
			},
		},
	},
	data() {
		return {
			loading: false,
			logList: [],
		}
	},
	watch: {
		bedInfo: {
			handler(val) {
				console.log(val, 'pensionBedId-buildLog')
			},
			deep: true,
			immediate: true,
		},
	},
	mounted() {
		if (this.bedInfo.id) {
			this.getList()
		}
	},
	methods: {
		getList() {
			this.loading = true
			checkBedLog({ pensionBedId: this.bedInfo.id })
				.then((res) => {
					this.logList = res.data
					this.loading = false
				})
				.catch(() => {
					this.logList = []
					this.loading = false
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.buildLog {
	max-height: 500px;
	overflow-y: auto;
	li {
		padding-bottom: 12px;
		font-size: 0.083333rem /* 16/192 */;
		.time {
			display: inline-block;
			width: 150px;
		}
	}
}
</style>
